import React from 'react';
import { ISendMessageForm, pickStrapiData, setGaClientId } from './helpers';
import { addHubspotUserToGA } from 'utils/helpers';
import { addHsProperty } from 'utils/api';

type WindowWithHubspot = Window & {
  dataLayer: Record<string, any>[];
  hbspt: any;
};
interface IHubspotForm {
  sendDataToStrapi: (data: ISendMessageForm) => Promise<string>;
}

declare const window: WindowWithHubspot;
const isBrowser = () => typeof window !== 'undefined';

const MESSAGE_ID = 'send_message';

export default function HubspotForm({ sendDataToStrapi }: IHubspotForm) {
  let gtmScript = '';
  React.useEffect(() => {
    if (isBrowser()) {
      const script = document.createElement('script');
      script.src = `${process.env.GATSBY_HS_FORM_SRC}`;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: `${process.env.GATSBY_HS_REGION}`,
            target: `#${MESSAGE_ID}`,
            portalId: `${process.env.GATSBY_HS_PORTAL_ID}`,
            formId: `${process.env.GATSBY_HS_FORM_ID}`,
            onFormReady: function (form: HTMLFormElement) {
              setGaClientId(form);
            },
            onBeforeFormSubmit: async function (
              form: HTMLFormElement,
              submissionValues: { name: string; value: string }[]
            ) {
              const hsValues = pickStrapiData(submissionValues);
              const { name, email, phone } = hsValues;

              const strapiContactId = await sendDataToStrapi(hsValues);
              addHsProperty({ userEmail: email, strapiId: strapiContactId });

              addHubspotUserToGA({ userName: name, email, phone });
              setGaClientId(form);
              gtmScript = `gtag('set', 'user_data', {
                "email": ${email}
                });`;
            },
          });
        }
      });
    }
  }, []);

  return <div id={MESSAGE_ID} dangerouslySetInnerHTML={{ __html: gtmScript }}></div>;
}
